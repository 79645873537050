import * as React from "react"
// import { Link } from "gatsby"
// import { Helmet } from "debug"
// import { Heading } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'

import Seo from "../../components/seo"
import AnimationWrapper from "../../components/animationWrapper"
import ContactSection from "../../components/contactSection"
import Container from "../../components/container"
import Header from "../../components/header"
import IconSection from "../../components/iconSection"
import ProductTilesSection from "../../components/productTilesSection"
import TextSection from "../../components/textSection"
import Layout from "../../components/layout"

import "./produkty.css"

const NadprozaPage = ( {data, location} ) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Zobacz!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section_main.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_main} />
          </AnimationWrapper>
        </Container>
      }

      <section id="zalety-nadproza-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <IconSection data={data.page.frontmatter} image={data.iconsZaletyNadprozaImg} location="homepage-keramzyt" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_section_secondary.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_secondary} />
          </AnimationWrapper>
        </Container>
      }

      <section id="nadproza-tiles-section" className="product-tiles-section">
        <ProductTilesSection data={data.nadprozaTiles} location="nadproza" />
      </section>

      <section id="zasady-oparcia-nadprozy-img" className="page-section">
        <Container>
          <AnimationWrapper>
            <StaticImage
              src="../../images/nadproza-1.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Zasady oparcia nadproży na murze"
            />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_section_bottom.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_bottom} />
          </AnimationWrapper>
        </Container>
      }

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "nadproza" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_secondary{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    iconsZaletyNadprozaImg: file(
      relativePath: {eq: "zalety-nadproza.png"}
    ){
      id
      publicURL
      childImageSharp {
        gatsbyImageData(
          transformOptions: {fit: COVER},
          webpOptions: {quality: 95}
        )
      }
      relativePath
    }
    nadprozaTiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(products)/"}},
        frontmatter: {category: {eq: "nadproza"}}
      }
    ){
      nodes {
        frontmatter {
          name
          slug
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
          image_second {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
          image_third {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
        }
        id
        body
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default NadprozaPage
